<template>
  <b-modal
    id="modal-export-product"
    ref="modalExportProduct"
    :title="`Export Produk Toko`"
    size="lg"
    scrollable
    ok-title="Export"
  >
    <b-row>
      <b-col>
        Berdasarkan hasil filter, {{ productData.length }} produk akan di export ke dalam file excel.
      </b-col>
    </b-row>
    <template #modal-footer>
        <b-button variant="outline-secondary" @click="openExportHistory">Export History</b-button>
        <b-button variant="danger" @click="$bvModal.hide('modal-export-product')">Tutup</b-button>
        <b-button variant="primary" @click="exportProducts">Export</b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BMedia,
  BImg,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BTbody,
  BThead,
  BButton,
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ModalExportProduct',
  props: {
    productData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    productDataLength() {
      return Array.isArray(this.productData) ? this.productData.length : 0;
    },
  },
  data() {
    return {
      shopOptions: [],
    };
  },
  components: {
    BRow,
    BCol,
    BModal,
    BMedia,
    BImg,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BTbody,
    BThead,
    BButton,
    BCard,
    BTabs,
    BTab,
  },
  async mounted() {
  },
  methods: {
  },
});
</script>
